//=====================GraphQl End Point=========================\\
export const BASE_URL = "https://api.bittenmusic.com/graphql"; // prodution api url 
// export const BASE_URL = " http://192.168.0.45/streaming/graphql"; // local

export const paymentHome = "https://bittenmusic.com";
// export const paymentHome = "https://localhost:3000";


// start update artist card success and cancel url 

// this variable used in local development 
// export const updateCardArtist_Success_URL = "https://localhost:3000/artist/profile"
// export const updateCardArtist_Cancel_URL = "https://localhost:3000/artist/profile"

// this artist login variable used in production 
export const updateCardArtist_Success_URL = "https://bittenmusic.com/artist/profile"
export const updateCardArtist_Cancel_URL = "https://bittenmusic.com/artist/profile"


// this variable used in local development 
// export const updateCardSubscriber_Success_URL = "https://localhost:3000/subscriber/subscriptions-plans"
// export const updateCardSubscriber_Cancel_URL = "https://localhost:3000/subscriber/subscriptions-plans"

// this subscriber login variable used in production 
export const updateCardSubscriber_Success_URL = "https://bittenmusic.com/subscriber/subscriptions-plans"
export const updateCardSubscriber_Cancel_URL = "https://bittenmusic.com/subscriber/subscriptions-plans"

// end update card success and cancel url 


export const BASE_IMAGE_URL = "";
export const SUB_DIRCTORY = "";
export const AdminRole = 1;
export const SubscriberRole = 2;
export const ArtistRole = 3;
export const FOLLOW_ARTIST = "Subscribe";
export const UNFOLLOW_ARTIST = "Unsubscribe";
//  notification type 1 is used for Artist and 2 for subscriber
// deviceType=1 for browser  and deviceType=2 for mobile

//amezon s3 credentials
export const AccessKey = "AKIAXODUN2TCCC5JPWAP";
export const SecretKey = "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";
export const ImageBucketName = "audioprofile";
export const region = "us-east-2";
export const imageMaxSize = "4000000";
export const audioMaxSize = "25000000";
export const profileDir = "Profile";
export const albumDir = "Album";
export const songThumDir = "Song Thumbnail";
export const myPlayListDir = "myplaylist";
export const SongAccessKey = "AKIAXODUN2TCLWIPTXWZ";
export const SongSecretKey = "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";
export const SongImageBucketName = "bittensource";
export const songDir = "audio-streaming-input";

export const profilePlaceholderImageUrl = 'https://images.unsplash.com/photo-1598387993441-a364f854c3e1?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=876&q=80';
export const GoogleMapsAPI = 'AIzaSyBa7QJtTI2MWLkF9R1xR1__LrolePBOeuI';
export const itemsPerPage = 6;
export const itemsPerPageForSubscriber = 10;
export const itemsPerPageForMostplayedSongs = 20;
export const displayGenreInDropDown = 20;
export const dateFormatForDatePicker = 'MM/dd/yyyy';
export const googleLocationSearchContries = ["us", "in"];
export const rowsPerPageOptions = [5, 10, 15, 20, 30];
export const adminModule_ItemPerPage = 30;
export const artistModule_ItemPerPage_report = 30;
export const preViewTime = 31;
export const googleLoginClientID = "122426053770-4gqb0one7mu4bk1inlqftsc99ocjmltl.apps.googleusercontent.com";
export const facebookLoginAppId = "662118715271619";
export const recaptchaKey = '6LcvK8YlAAAAAHUMTdQ0FR2WstPqA6JVCSGMk_m0'